<template>
  <div id="contact">
		<Header />
		<div class="page-content">
			<section class="primary-bg">
				<div class="page-title app-max-width">
					<div class="app-marg">
						<h1 class="">CONTACT US</h1>
					</div>
				</div>
			</section>
			<section class="primary">
				<div class="page-title app-max-width">
					<div class="app-marg">
						<div class="app-min-h">
							<p>This is the contact page.</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<Footer />
  </div>
</template>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";

	export default {
		name: 'Contact',
		components: {
			Header,
			Footer
		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style scoped>
	h1 {
		padding: 40px 0;
	}
	.app-min-h {
		width: auto;
		min-height: 400px;
	}
</style>