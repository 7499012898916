<template>
  <div class="slider-container">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="../assets/img/suite-detail.jpg" alt="First slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/suite-detail.jpg" alt="Second slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/suite-detail.jpg" alt="Third slide">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      };
    },
    methods: {
      
    }
  }
  
</script>

<style scoped>
.carousel-caption {
  background-color: rgba(255,255,255,.5);
  color: #000;
  width: 100%;
  position: absolute;
  left: 0;
}
.carousel-caption h3 {
  font-size: 1rem;
  font-weight: bold;
}
.carousel-caption p {
  display: none;
}
.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: rgba(255,255,255,1);
}
.carousel-indicators li.active {
  background-color: #f89f20;
}


@media screen and (min-width: 600px) { 
  .carousel-caption h3 {
     font-size: 1.45rem;
  }
  .carousel-caption p {
    display: block;
    font-size: 20px;
  }
  .carousel-indicators {
    
  }
  .carousel-inner, .carousel-item {
    height: 600px;
  }
}


@media screen and (min-width: 900px) {
  .carousel-caption h3 {
    font-size: 1.75rem;
  }
  .carousel-caption p {
    font-size: 22px;
  }

}
</style>
