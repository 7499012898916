<template>
	<div id="home">
		<Header />
		<section>
			<div class="hero">
				<div class="hero-container app-max-width">
					<div class="hero-content app-marg">
						<h1 class="hero-title">PREMIUM SEATING</h1>
						<h2 class="secondary">THE BEST WAY TO ENJOY ALL THE EXCITEMENT</h2>
						<div>
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus ut nisi explicabo repellendus, saepe quisquam eveniet, quas magni voluptatibus vitae. Provident, laudantium corporis quam voluptate temporibus illo.</p>
						</div>
						<div class="btn-container">
							<button class="transition"
									@click="linkToContact()">CONTACT US</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="cta">
				<cta />
			</div>
		</section>
		<section>
			<div class="options-section primary-bg">
				<seatingOptions />
			</div>
		</section>
		<section>
			<div class="club">
				<club />
			</div>
		</section>
		<Footer />
	</div>
</template>

<script>
  import Cta from "@/components/Cta.vue";
  import SeatingOptions from "@/components/SeatingOptions.vue";
  import Club from "@/components/Club.vue";
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";

	export default {
		name: 'Home',
		components: {
			Cta,
			SeatingOptions,
			Club,
			Header,
			Footer
		},
		data() {
			return {

			};
		},
		methods: {
			linkToContact() {
				this.$router.push('/contact');
			}
		}
	}
</script>

<style scoped>
.hero {
	width: 100%;
	height: 100vh;
	max-height: 700px;
	background-image: url(../assets/img/header-bg.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}
.hero-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.hero-content {
	width: auto;
	height: 100vh;
	max-height: 600px;
	text-align: center;
	padding-top: 100px;
}
.hero-content .hero-title {
  font-size: 42px;
}
.hero-content h2{
  font-weight: lighter;
}
.hero-content h1, .hero-content h2 {
	padding: 5px;
}
.hero-content p {
	color: #fff;
	padding: 20px 5px;
  font-weight: bold;
}
.btn-container {
	margin: 20px 0;
}
.hero-content button {
	color: #fff;
	background-color: transparent;
	border: 1px solid #fff;
	padding: 10px 25px;
	border-radius: .3em;
	cursor: pointer;
}
.hero-content button:hover {
	color: #000;
	background-color: #fff;
}



@media screen and (min-width: 450px) {
	.hero-content {
		padding-top: 150px;
	}
}



@media screen and (min-width: 600px) {
	.hero-content {
		padding-top: 180px;
	}
}



@media screen and (min-width: 900px) {
	.hero-content {
		padding-top: 160px;
	}
}
</style>