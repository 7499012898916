<template>
  <div id="detail">
		<Header />
		<div class="page-content">
			<section class="primary-bg">
				<div class="page-title app-max-width">
					<div class="page-title-container app-marg">
						<h3>EIDE BAILLY SUITE LEVEL</h3>
						<div class="banner-btn-container">
							<button class="transition">
								<span class="bubble-icon secondary transition">&#x2751;</span>
								<span>DOWNLOAD INFO</span>
							</button>
							<button class="transition">
								<span>CONTACT US</span>
							</button>
						</div>
					</div>
				</div>
			</section>
			<section>
				<Slider />
			</section>
			<section>
				<div class="alert-section secondary-bg">
					<div class="app-max-width">
						<div class="imp-content app-marg">
							<span class="bubble-icon primary-bg secondary">&#x2139;</span>
							<p class="primary">Did you know? Whether you're closing an important business deal or celebrating with your employees, renting a suite for a Jazz game is perfect for any occasion. Call or text 801.325.2203 to learn more.</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="detail-info primary-bg">
					<div class="app-max-width">
						<div class="info-container app-marg">
							<div class="info-content">
								<div class="about-block block-pad">
									<div class="block-title"><h3>ABOUT</h3></div>
									<div class="block-container">
										<div class="block block-1">
											<div class="icon-block"><span class="bubble-icon primary">&#x2610;</span></div>
											<div class="block-content">
												<div class="block-header"><h3>Location</h3></div>
												<div class="block-body">
													<p>Suite Level (Level 4)</p>
													<button class="secondary-bg transition">View Arena Map</button>
												</div>
											</div>
										</div>
										<div class="block block-2">
											<div class="icon-block"><span class="bubble-icon primary">&#x2610;</span></div>
											<div class="block-content">
												<div class="block-header"><h3>Capacity</h3></div>
												<div class="block-body">
													<p>Accommodates 18 - 32 people</p>
												</div>
											</div>
										</div>
										<div class="block block-3">
											<div class="icon-block"><span class="bubble-icon primary">&#x2610;</span></div>
											<div class="block-content">
												<div class="block-header"><h3>Event Availability</h3></div>
												<div class="block-body">
													<p>Utah Jazz Games (including playoffs)</p>
													<p>Concerts and select special events</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="divider"></div>
								<div class="benefit-block block-pad">
									<div class="block-title"><h3>BENEFITS &#38; AMENITIES</h3></div>
									<div class="block-container">
										<div class="block block-1">
											<div class="icon-block"></div>
											<div class="block-content">
												<div class="block-body">
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>Private Suite</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>420–490 square feet depending on the location</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>Lounge seating area for four with coffee table at the back of suite</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>High-top counter in middle, and drink rails at the back of the fixed-seating area</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>Dedicated suite attendant for each game and arena event</p>
													</div>
												</div>
											</div>
										</div>
										<div class="block block-2">
											<div class="icon-block"></div>
											<div class="block-content">
												<div class="block-body">
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>Flat screen TV in each suite</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>WiFi access</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>18–32 tickets in a luxury suite (varies by location)</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>VIP parking passes</p>
													</div>
													<div class="list-w-icon">
														<span class="bubble-icon primary">&#10003;</span>
														<p>Food credit for catering to both Utah Jazz games and arena events</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="divider"></div>
								<div class="parking-block block-pad">
									<div class="block-title"><h3>PARKING &#38; CHECK-IN</h3></div>
									<div class="block-container">
										<div class="block block-1">
											<div class="icon-block"><span class="bubble-icon primary">&#x2610;</span></div>
											<div class="block-content">
												<div class="block-body">
													<p>VIP parking in the Park Place lot located right next to Vivint Smart Home Arena</p>
													<button class="secondary-bg transition">View Arena Map</button>
												</div>
											</div>
										</div>
										<div class="block block-2">
											<div class="icon-block"><span class="bubble-icon primary">&#x2610;</span></div>
											<div class="block-content">
												<div class="block-body">
													<p>Easy check-in: Talk to the host at any suite level (level 4) entrance to check-in</p>
													<button class="secondary-bg transition">View CHECK-IN LOCATION</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="divider"></div>
								<div class="uses-block block-pad">
									<div class="block-title">
										<span class="bubble-icon primary">&plus;</span>
										<h3>POSSIBLE USES</h3>
									</div>
									<div class="uses-container">
										<div class="block-content">
											<div class="block-header"><h4>Business Development</h4></div>
											<div class="divider"></div>
											<div class="block-body">
												<p>Client Entertainment</p>
												<p>Relationship Development</p>
												<p>Generate Referrals by Inviting Clients to Bring Colleagues & Friends</p>
											</div>
										</div>
										<div class="block-content">
											<div class="block-header"><h4>Client Retention</h4></div>
											<div class="divider"></div>
											<div class="block-body">
												<p>Renew Accounts</p>
												<p>Reduce Attrition</p>
												<p>Relationship Development</p>
												<p>Current Customer Upsell</p>
												<p>New Product/Service Launch</p>
											</div>
										</div>
										<div class="block-content">
											<div class="block-header"><h4>Employee Usage</h4></div>
											<div class="divider"></div>
											<div class="block-body">
												<p>Employee Recruitment & Retention</p>
												<p>Employee Benefit Programs</p>
												<p>Employee Incentives</p>
												<p>Internal Contests</p>
												<p>Team Building</p>
												<p>Employee Families</p>
												<p>Holiday Events/Parties</p>
												<p>Conferences</p>
											</div>
										</div>
										<div class="block-content">
											<div class="block-header"><h4>Executive Utilization</h4></div>
											<div class="divider"></div>
											<div class="block-body">
												<p>Executive Team Building</p>
												<p>Quarterly Meetings</p>
												<p>Investors & Shareholders</p>
												<p>Personal Entertainment</p>
											</div>
										</div>
										<div class="block-content">
											<div class="block-header"><h4>Community Relations</h4></div>
											<div class="divider"></div>
											<div class="block-body">
												<p>Complement Current Charitable Programs</p>
												<p>Donate for Auctions or Raffles</p>
												<p>Host 501(c)(3) Organizations</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="food-option secondary-bg">
					<div class="food-option-container">
						<div class="food-block">
							<div class="app-max-width">
								<h4 class="app-marg">EXPERIENCE WOLRD-CLASS FOOD AND BEVERAGE OPTIONS</h4>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<Footer />
  </div>
</template>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import Slider from "@/components/Slider.vue";

	export default {
		name: 'Detail',
		components: {
			Header,
			Footer,
			Slider
		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style scoped>
	.page-title {
		position: relative;
		display: flex;
		padding: 30px 0;
	}
	.page-title h3 {
		color: #fff;
	}
	.detail-info h3 {
		font-weight: bold;
	}
	.page-title-container {
		width: 100%;
		display: block;
		position: relative;
	}
	.banner-btn-container {
		display: flex;
		position: relative;
		justify-content: center;
		right: 0;
	}
	.banner-btn-container button {
		display: flex;
		align-items: center;
		padding: 5px 10px;
		border-radius: .2em;
		border: 1px solid #f89f20;
		background-color: transparent;
		color: #fff;
		margin: 15px 0 10px 15px;
		margin-left: 15px;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;
	}
	.banner-btn-container button .bubble-icon {
		font-size: 18px !important;
		padding: 0;
		margin-right: 10px;
		border-radius: initial;
		width: auto;
		height: auto;
	}
	.banner-btn-container button:hover {
		color: #fff;
		background-color: #f89f20;
	}
	.banner-btn-container button:hover .bubble-icon {
		color: #fff;
	}
	.alert-section {
		display: flex;
		align-items: center;
		padding: 20px;
	}
	.alert-section p {
		margin: 0;
		font-weight: bold;
	}
	.imp-content {
		display: flex;
		align-items: center;
		align-items: center;
	}
	.bubble-icon {
		padding: 10px;
		margin-right: 20px;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 42px;
		font-weight: bold;
	}
	.uses-block .bubble-icon {
		background-color: #9dc6ff;
	}
	.block-pad {
		padding: 60px 0 30px;
	}
	.info-content {
		color: #fff;
		display: block;
	}
	.divider {
		width: 100%;
		height: 1px;
		background-color: #9dc6ff;
	}
	.block-title {
		width: 100%;
		display: flex;
		margin-bottom: 50px;
	}
	.block-title h3 {
		margin: 0;
	}
	.about-block, .benefit-block, .parking-block {
		display: block;
		width: 100%;
	}
	.block-container {
		display: flex;
		width: 100%;
	}
	.about-block .block-container, .benefit-block .block-container, .parking-block .block-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	.about-block .block, .parking-block .block {
		display: flex;
		align-items: baseline;
	}
	.about-block .bubble-icon, .parking-block .bubble-icon {
		color: #9dc6ff;
	}
	.block {
		width: 100%;
		padding-right: 0;
	}
	.block:last-child {
		padding-right: 0;
	}
	.block-header {
		margin-bottom: 20px;
	}
	.block-header h4 {
		font-weight: bold;
	}
	.block-body p {
		font-size: 20px;
	}
	.uses-block {
		display: block;
	}
	.uses-block .block-title {
		width: 100%;
		align-items: center;
	}
	.uses-block p {
		font-size: 16px;
		margin-bottom: 8px;
	}
	.uses-block .block-body {
		margin-top: 20px;
	}
	.uses-container {
		display: flex;
		flex-wrap: wrap;
	}
	.block-content {
		width: 100%;
		padding-right: 0;
		margin-bottom: 40px;
	}
	.benefit-block .block-1 .block-content {
		margin-bottom: 0;
	}
	.detail-info button {
		border: 1px solid #f89f20;
		border-radius: .2em;
		color: #0d233f;
		padding: 5px 10px;
		font-weight: bold;
		font-size: 14px;
	}
	.detail-info button:hover {
		color: #f89f20;
		border: 1px solid #f89f20;
		background-color: transparent;
	}
	.detail-info button:hover span {
		background-color: #f89f20;
		color: #000;
	}
	.list-w-icon {
		display: flex;
		align-items: baseline;
	}
	.list-w-icon span {
		color: #0d233f;
		width: 20px;
		height: 20px;
		font-size: 18px;
		margin-right: 20px;
		background-color: #9dc6ff;
	}
	.food-option {
		background-image: url(../assets/img/food-bg.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	.food-option-container {
		width: 100%;
		height: 400px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		color: #fff;
	}
	.food-block {
		width: 100%;
		height: auto;
		background-color: rgba(0,0,0,.7);
	}
	.food-block h4 {
		color: #fff;
		padding: 30px;
		margin: 0;
		text-align: center;
	}


@media screen and (min-width: 600px) {
	.banner-btn-container {
		justify-content: flex-end;
	}
	.block-container {
    display: flex;
    width: 100%;
	}
	.block-content {
		width: 100%;
		padding-right: 0;
		margin-bottom: 40px;
	}
	.about-block .block, .block-container .block {
		width: 50%;
		padding-right: 3%;
	}
	.about-block .block:nth-child(3) {
		padding-right: 0;
	}
	.about-block .block:nth-child(3) {
		width: 100%;
	}
	.parking-block .block:first-child {
		padding-left: 0;
	}
	.parking-block .block:last-child {
		padding-right: 0;
	}
	.benefit-block .block-container {
		flex-wrap: nowrap;
	}
	.benefit-block .block {
		padding: 0 5%;
	}
	.benefit-block .block:first-child {
		padding-left: 0;
	}
	.benefit-block .block:last-child {
		padding-right: 0;
	}
	.benefit-block .block-1 .block-content {
		margin-bottom: 40px;
	}
	.uses-block .block-content {
    width: calc(100% / 3);
    padding-right: 5%;
    margin-bottom: 40px;
	}
	.uses-block .block-content:nth-child(3) {
		padding-right: 0;
	}
	.uses-block .block-content:last-child {
		padding-right: 0;
	}
}




@media screen and (min-width: 900px) {
	.page-title-container {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
	}
	.banner-btn-container {
		display: flex;
		position: absolute;
		right: 0;
	}
	.banner-btn-container button {
		margin-left: 20px;
	}
	.about-block, .benefit-block, .parking-block {
		display: flex;
		width: 100%;
	}
	.about-block .block-container, .parking-block .block-container {
    flex-wrap: nowrap;
	}
	.about-block .block {
		width: 100%;
    padding: 0;
    margin-bottom: 0;
	}
	.about-block .block:last-child {
    width: 100%;
    padding-right: 0;
	}
	.about-block .block .block-content {
    padding-right: 5%;
	}
	.block-title {
		width: 250px;
		display: flex;
		margin-bottom: 40px;
	}
	.uses-block .block-content {
    width: calc(100% / 5);
    padding-right: 5%;
    margin-bottom: 40px;
	}
	.block {
		width: 100%;
		padding: 0 3%;
	}
	.uses-block .block-content:nth-child(3) {
		padding-right: 5%;
	}
	.uses-block .block-content:last-child {
		padding-right: 0;
	}
}

</style>
