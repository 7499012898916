<template>
  <div class="club-container app-max-width section-pad">
		<div class="club-elmt app-marg">
			<div class="club-content">
				<h1 class="primary">UTAH JAZZ 100 CLUB</h1>
				<div class="block">
					<div class="block-elmt block-1">
						<h3>One of the most premier clubs in the state of Utah.</h3>
						<p>The Jazz 100 Club is an elite membership with the Utah Jazz that operates like a personal seat license with a guaranteed value. Consisting only of seats located in the first five rows from the court, 100 Club members enjoy the best seats and some of the most exclusive perks in Vivint Smart Home Arena and throughout the National Basketball Association (NBA). The Jazz 100 Club is uniquely governed by a member-based advisory board in conjunction with senior leadership from Larry H. Miller Sports & Entertainment. Jazz 100 Club members enjoy an unparalleled comradery and network as home to many of the most prominent and successful business and community leaders in the Western United States.</p>
						<div class="more-info">
							<a href="#" class="secondary">Click here for more information</a>
						</div>
					</div>
					<div class="block-elmt block-2">
						<img alt="Utah Jazz logo" src="../assets/img/100club.jpg" />
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>

	export default {
		components: {
			
		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style scoped>
	.club-content {
		position: relative;
		display: block;
	}
	h1 {
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 15px;
	}
	.block {
		display: block;
	}
	.block-elmt {
		width: 100%;
		height: auto;
	}
	.block-2 {
		margin-top: 30px;
	}
	.block-elmt img {
		width: 100%;
		height: auto;
	}
	.more-info {
		margin-top: 20px;
	}
	.more-info a {
		text-decoration: underline;
		font-weight: bold;
	}



	@media screen and (min-width: 700px) {
		.block {
			display: flex;
		}
		.block-1 {
			margin-right: 20px;
		}
		.block-2 {
			margin-left: 20px;
			margin-top: 0;
		}
	}



	@media screen and (min-width: 900px) {
		.block-1 {
			margin-right: 40px;
		}
		.block-2 {
			margin-left: 40px;
		}
	}
</style>