<template>
  <router-view />
</template>

<script>

  export default {
    name: 'App',
    components: {
      
    }
  }
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
*, *:before, *:after {
  box-sizing: border-box;
}
ol, ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
#app {
  min-width: 350px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.primary-bg {
  background-color: #0d233f;
}
.primary {
  color: #0d233f;
}
.secondary-bg {
  background-color: #f89f20;
}
.secondary {
  color: #f89f20;
}
.invisible {
  display: none;
}
.transition {
  transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
}
.app-max-width {
  max-width: 1400px;
  margin: auto;
}
.app-tb-padding {
  padding: 100px 0;
}
.app-tb-padding-50 {
  padding: 50px 0;
}
.app-tb-padding-70 {
  padding: 70px 0;
}
.app-tb-padding-100 {
  padding: 100px 0;
}
.app-marg {
  margin: 0 4%;
}
.section-pad {
  padding: 70px 0;
}
#detail .header, #contact .header {
  background-color: #0d233f;
  position: relative;
}
</style>
