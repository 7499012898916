<template>
	<div class="cta-container app-max-width">
		<div class="action-elmt app-marg">
			<div class="box box-1">
				<div class="box-header"></div>
				<div class="box-body primary-bg">
					<h2 class="secondary">LUXURY SUITES</h2>
					<div class="box-content">
						<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis quam accusamus, quod, architecto, repudiandae dolorum deserunt dolore ipsa corporis quo voluptate voluptas quos saepe odit eius id.</p>
						<div class="btn-container">
							<button class="secondary transition">LEARN MORE</button>
						</div>
					</div>
				</div>
			</div>

			<div class="box box-2">
				<div class="box-header"></div>
				<div class="box-body primary-bg">
					<h2 class="secondary">EXCLUSIVE CLUBS</h2>
					<div class="box-content">
						<p>Lorem ipsum dolor sit, amet consectetur, adipisicing elit. Porro cumque, id, et placeat suscipit atque nobis deserunt explicabo doloremque aliquid, consectetur.</p>
						<div class="btn-container">
							<button class="secondary transition">LEARN MORE</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		components: {

		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style scoped>
.cta-container {
	position: relative;
}
.action-elmt {
	display: block;
	position: relative;
	bottom: 80px;
}
.box {
	width: 90%;
	height: auto;
	margin: 30px auto;
	color: #fff;
}
img {
	width: 100%;
	height: auto;
}
.box-header {
	width: auto;
	height: 170px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.box-1 .box-header {
	background-image: url('../assets/img/suites.jpg');
}
.box-2 .box-header {
	background-image: url('../assets/img/clubs.jpg');
}
.box-body {
	padding: 20px 30px;
}
.btn-container {
	margin-top: 20px;
}
.btn-container button {
	padding: 5px 10px;
	border: 1px solid #f89f20;
	border-radius: .2em;
	cursor: pointer; 
	background-color: transparent;
}
.btn-container button:hover {
	color: #fff;
	background-color: #f89f20;
}

@media only screen and (min-width: 600px) {
	.action-elmt {
		display: flex;
		justify-content: center;
		position: relative;
		bottom: 70px;
	}
	.box {
		width: 40%;
		height: auto;
		margin: 0 auto;
		color: #fff;
	}
}



@media screen and (min-width: 900px) {
	
}
</style>
