<template>
	<footer class="primary-bg">
		<div class="footer-container app-max-width">
			<div class="app-marg">
				<h1>CONTACT US</h1>
				<div class="footer-content">
					<div class="first-block">
						<div class="phone"><a href="tel:801-325-2203" class="transition">801.325.2203</a></div>
						<div class="email"><a href="mailto:premium@utahjazz.com" class="transition">premium@utahjazz.com</a></div>
						<div class="description">
							<p>With options like single-game tickets, suite rentals and special group rates, experiencing Utah Jazz basketball has never been more convenient! Just let us know what you're interested in and we can customize a package to fit your group and budget.</p>
						</div>
					</div>
					<hr>
					<div class="second-block">
						<ContactForm />
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import ContactForm from "@/components/ContactForm.vue";

	export default {
		components: {
			ContactForm
		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style scoped>
footer {
	color: #fff;
	padding: 60px 0;
}
.footer-container {
	position: relative;
}
.footer-content {
	display: block;
}
.footer-content hr {
	width: 30%;
	margin: 40px auto;
}
h1, .phone, .email {
	font-weight: bold;
	padding: 10px 0;
}
.description {
	padding: 10px 0;
}
.first-block {
	padding-right: 0;
}
.second-block {
	padding-left: 0;
}
.phone a, .email a {
	color: #fff;
}
.phone a:hover, .email a:hover {
	color: #f89f20;
	text-decoration: none;
}



@media only screen and (min-width: 480px) {
	
}



@media screen and (min-width: 600px) {
	.footer-content {
		display: block;
	}
}



@media screen and (min-width: 800px) {
	.footer-content {
		display: flex;
	}
	.footer-content hr {
		display: none;
	}
	.first-block {
		padding-right: 40px;
	}
	.second-block {
		padding-left: 40px;
	}
}



@media screen and (min-width: 900px) {	
	.first-block {
		max-width: 500px;
	}
	.second-block {
		width: 100%;
	}
}
</style>