<template>
  <div class="not-found primary-bg">
	<div>
		<h1>The page you are looking for cannot be found.</h1>
	</div>
  </div>
</template>

<script>

	export default {
		name: 'About',
		components: {
			
		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style>
.not-found {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
h1 {
	color: #fff;
}
	
</style>
