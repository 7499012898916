<template>
	<div class="header">
		<div class="header-container app-max-width">
			<div class="header-content app-marg">
				<div class="logo-container">
					<a href="/">
						<span class="logo"><img alt="Utah Jazz logo" src="../assets/logo.png" /></span>
						<span class="comp-name">UTAH JAZZ PREMIUM SEATING</span>
					</a>
				</div>
				<div class="nav-container">
					<div class="menu-button transition" :class="{'close-nav': isNavActive}" @click="activateMenu">
						<span class="menu-icon"></span>
					</div>
					<ul class="nav">
						<li>
							<router-link to="/">LUXURY SUITES <span></span></router-link>
						</li>
						<li>
							<router-link to="/detail">EXCLUSIVE CLUBS <span></span></router-link>
						</li>
						<li>
							<router-link to="/contact">CONTACT US</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isNavActive: false
			};
		},
		methods: {
			activateMenu() {
				if (this.isNavActive == false) {
					this.isNavActive = true;
				} else {
					this.isNavActive = false;
				}
			},
			deactivateMenu() {
				this.isNavActive = false;
			}
		}
	}
</script>

<style scoped>
.header {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	font-size: 13px;
}
.header-container {
	position: relative;
}
.header-content {
	width: auto;
	height: auto;
	padding: 10px 0;
	display: flex;
	align-items: center;
	position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, .8);
}
.logo-container {
	display: inline-flex;
	align-items: center;
}
.logo-container a {
	display: flex;
	align-items: center;
	position: relative;
}
.logo {
	width: auto;
	height: auto;
	display: block;
}
.logo img {
	width: auto;
	height: 40px;
}
.menu-button {
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	width: 40px;
	margin-right: 10px;
	transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	position: absolute;
	right: 0;
	z-index: 9999;
}
.menu-button span, .menu-button::after, .menu-button::before {
	display: block;
	content: '';
	margin: 5px 0;
	height: 5px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	background-color: #fff;
	transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
}
.close-nav:before {
	transform: translateY(10px) rotate(135deg);
}
.close-nav:after {
	transform: translateY(-10px) rotate(-135deg);
}
.close-nav span {
	transform: scale(0);
	opacity: 0;
}
.nav {
	/*display: none;*/
}
.comp-name {
	color: #fff;
	margin-left: 10px;
	font-weight: bold;
}
.nav-container {
	display: flex;
  align-items: center;
	position: absolute;
  right: -10px;
}
.nav {
	display: none;
	list-style: none;
}
/*.nav-container li a {
	color: #f89f20;
	padding: 5px 10px;
}
.nav li span::after {
	content: "\25BE";
	color: #fff;
	margin-left: 3px;
}
.dp-content {
	display: none;
}*/


@media screen and (min-width: 800px) {
	.menu-button {
		display: none;
	}
	.nav {
		display: flex;
		list-style: none;
	}
	.nav-container li a {
		color: #f89f20;
		padding: 5px 10px;
	}
	.nav li span::after {
		content: "\25BE";
		color: #fff;
		margin-left: 3px;
	}
	.dp-content {
		display: none;
	}
}
</style>