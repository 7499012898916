<template>
	<div class="customer-form">
		<div class="error-field alert alert-danger"
				v-if="errors.length">
			<div class="error-container">
				<b>Please correct the following error(s):</b>
				<ul>
					<li v-for="(error, i) in errors" :key="i"><b>{{ i+1 }}.</b> {{ error }}</li>
				</ul>
			</div>
		</div>
		<form>
			<div class="form-wrapper">
				<div class="form-container">
					<div class="group">
						<div class="group-elmt">
							<label for="ci_1">First Name:</label>
							<input
								type="text"
								id="ci_1"
								name="cust_first_name"
								class="form-control"
								:class="{'error-border': customerInfo.firstName.hasError}"
								placeholder="First Name"
								v-model="customerInfo.firstName.info">
						</div>
						<div class="group-elmt">
							<label for="ci_1">Last Name:</label>
							<input
								type="text"
								id="ci_1"
								name="cust_last_name"
								class="form-control"
								:class="{'error-border': customerInfo.lastName.hasError}"
								placeholder="Last Name"
								v-model="customerInfo.lastName.info" >
						</div>
					</div> <!-- Group Item -->
					<div class="group">
						<div class="group-elmt">
							<label for="ci_2">Email:</label>
							<input
							type="text"
							id="ci_2"
							name="cust_email"
							class="form-control"
							:class="{'error-border': customerInfo.email.hasError}"
							placeholder="Email"
							v-model="customerInfo.email.info">
						</div>
					</div> <!-- Group Item -->
					<div class="group textarea-section">
						<div class="group-elmt">
							<label for="ci_5">Message:</label>
							<textarea 
									id="ci_5" 
									name="cust_message" 
									rows="5" cols="50"
									placeholder="Leave a message"
									:class="{'error-border': customerInfo.message.hasError}"
									v-model="customerInfo.message.info"></textarea>
						</div>
					</div> <!-- Group Item -->

					<div class="submit-btn-container">
						<div class="btn-container text-right">
							<button
									class="submit-btn secondary-bg transition"
									@click.prevent="submittedForm">
								<b class="btn-txt"><span class="transition">&#10003;</span>SEND INQUIRY</b></button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>

export default {
	data() {
		return {
			customerInfo: {
				firstName: {
					title: "First Name",
					info: "",
					hasError: false
				},
				lastName: {
					title: "Last Name",
					info: "",
					hasError: false
				},
				email: {
					title: "Email",
					info: "",
					hasError: false
				},
				phone: {
					title: "Phone",
					info: "",
					hasError: false
				},
				message: {
					title: "Message",
					info: "",
					hasError: false
				}
			},
			errors: []
		};
	},
	methods: {
		submittedForm() {

		}
	}
}
</script>

<style scoped>
.customer-form {
	position: relative;
	padding: 10px 0;
}
.form-container {
	position: relative;
}
.group {
	display: block;
}
.group-elmt {
	width: 100%;
	margin-bottom: 10px;
}
.group-elmt:last-child {
	margin-right: 0;
}
.group-elmt label {
	display: block;
	margin-bottom: 5px;
}
input, textarea {
	min-width: 100%;
	max-width: 100%;
	border: 0;
	border-bottom: 1px solid rgba(104, 132, 138, 0.4);
	padding: 5px 10px;
	border-radius: .2em;
	transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
}
::placeholder {
	color: #ccc;
	opacity: 1;
}
::-ms-input-placeholder {
	color: #ccc;
}
::-ms-input-placeholder {
	color: #ccc;
}
input:focus, textarea:focus, button:focus {
	border-color: #68848a;
	box-shadow: none;
	outline: none;
}
input:hover, textarea:hover {
	border-bottom: 1px solid #68848a;
	color: #d39e00;
}
.btn-container {
	position: relative;
}
.submit-btn {
	border: 1px solid #f89f20;
	padding: 10px 20px;
	border-radius: .2em;
	cursor: pointer;
}
.submit-btn span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #f89f20;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
.submit-btn:hover {
	color: #f89f20;
	border: 1px solid #f89f20;
	background-color: transparent;
}
.submit-btn:hover span {
	background-color: #f89f20;
  color: #000;
}



@media only screen and (min-width: 480px) {
	.group-elmt {
		margin-right: 30px;
	}
}



@media screen and (min-width: 600px) {
	
}



@media screen and (min-width: 800px) {
	.group {
		display: block;
		margin-bottom: 10px;
	}
	.group-elmt {
		margin-right: 30px;
	}
}



@media screen and (min-width: 900px) {	
	.group {
		display: flex;
		margin-bottom: 10px;
	}
	.group-elmt {
		margin-right: 50px;
	}
}
</style>
