<template>
  <div class="option-container app-max-width section-pad">
		<div class="option-elmt app-marg">
			<h1 class="secondary">EXPLORE OUR PREMIUM SEATING OPTIONS</h1>
			<div class="seatings">
				<div class="option option-1"><router-link to="/detail" tag="a">Eide Bailly Suite Level</router-link></div>
				<div class="option option-2"><router-link to="/detail" tag="a">Example</router-link></div>
				<div class="option option-3"><router-link to="/detail" tag="a">Example</router-link></div>
				<div class="option option-4"><router-link to="/detail" tag="a">Example</router-link></div>
				<div class="option option-5"><router-link to="/detail" tag="a">Example</router-link></div>
				<div class="option option-6"><router-link to="/detail" tag="a">Example</router-link></div>
			</div>
		</div>
  </div>
</template>

<script>

	export default {
		components: {
			
		},
		data() {
			return {

			};
		},
		methods: {

		}
	}
</script>

<style scoped>
	.seatings {
		display: block;
		margin-top: 20px;
	}
	.option {
		width: calc(50% - 14px);
		height: 150px;
		display: inline-block;
		margin: 7px;
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-image: url('../assets/img/loge-box.jpg');
	}
	.option a {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		font-size: 18px;
		font-weight: bold;
		padding: 5px;
		text-align: center;
		color: #fff;
		background-color: rgba(0,0,0,.6);
		transition: all .4s ease-in-out;
		-moz-transition: all .4s ease-in-out;
		-webkit-transition: all .4s ease-in-out;
	}
	.option a:hover {
		background-color: rgba(0,0,0,.3);
	}



	@media screen and (min-width: 600px) {
		.option {
			width: calc((100% / 3) - 14px);
		}
	}
</style>